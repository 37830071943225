import Image from 'next/image'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { UserListItem } from '@common/UserListItem'
import { useBottomSheet } from '@context/BottomSheetContext'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import NodePill from '@mobile/NodePill'
import { IntoUrl } from '@models/IntoUrl'
import { IntoUser } from '@models/IntoUser'
import urlApi from '@redux/api/urlApi'

interface LikersAndTopicsProps {
  url: IntoUrl
}

const LikersAndTopics: React.FC<LikersAndTopicsProps> = ({ url }) => {
  return (
    <div
      className="relative flex w-full items-center"
      style={{
        maskImage: 'linear-gradient(to right, black 90%, transparent 100%)',
        WebkitMaskImage: 'linear-gradient(to right, black 90%, transparent 100%)',
      }}
    >
      <div className="flex items-center overflow-x-scroll scrollbar-hide">
        <Likers url={url} />
        {url.nodes?.map(node => <NodePill key={node.slug} slug={node.slug} className="!m-0 !mr-2" />)}
      </div>
    </div>
  )
}

const Likers = ({ url }: { url: IntoUrl }) => {
  const { currentUserID } = useCurrentUserContext()
  const likersRef = useRef<IntoUser[]>([])
  const { data: likers = [] } = urlApi.useGetLikersQuery(
    {
      urlId: url.url_id,
      cookie: null,
    },
    { skip: likersRef.current.length > 0 }
  )
  const { openBottomSheet } = useBottomSheet()

  useEffect(() => {
    likersRef.current = likers
  }, [likers])

  const filteredUsers = useMemo(
    () => likersRef.current.filter(user => user.meta.isLiked || user.user_id === currentUserID),
    [currentUserID]
  )

  const onClickUsers = useCallback(() => {
    openBottomSheet(
      <div className="flex-1 overflow-auto p-4 lg:p-6">
        <p className="mb-6 text-xl font-medium">Likers</p>
        <div className="flex-1 space-y-4">
          {likersRef.current.map(user => (
            <UserListItem key={user.user_id} userId={user.user_id} />
          ))}
        </div>
      </div>
    )
  }, [openBottomSheet])

  return (
    <>
      {filteredUsers.map(user => (
        <Image
          key={user.user_id}
          className="mr-2 aspect-square size-9 rounded-full border-2 border-secondary bg-tertiary object-cover"
          src={user.photo_url ?? ''}
          alt={user.display_name}
          height={44}
          width={44}
          onClick={onClickUsers}
        />
      ))}
    </>
  )
}

export default LikersAndTopics
