import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useIsVideo } from '@hooks/useIsVideo'
import { useVideoPlayer } from '@hooks/useVideoPlayer'
import IconGIF from '@icons/IconGIF.svg'
import { resolveMedia } from '@lib/mediaResolver'
import { globals } from '@lib/messages/protobuf'
import PlayPauseToggle from '@mobile/PlayPauseToggle'
import ProgressBar from '@mobile/ProgressBar'
import { VolumeControl } from '@mobile/VolumeControl'
import { IntoUrl } from '@models/IntoUrl'
import { UrlMedia } from '@pages/url/components/UrlMedia'
import { UrlShareDetails } from '@pages/url/components/UrlShareDetails'
import { useCurrentUrlContext } from '@pages/url/CurrentUrlContext'
import UrlActions from './Actions'
import LikersAndTopics from './LikersAndTopics'
import UrlTitle from './Title'

export const Z_VIDEO_OVERLAY = 'z-[51]'

const Url = ({ url, className, isActive }: { url: IntoUrl; className?: string; isActive: boolean }) => {
  const { isLoggedIn } = useCurrentUserContext()
  const { perceivedMediaType } = useCurrentUrlContext()
  const { provider } = resolveMedia(url)
  const { playerRef } = useVideoPlayer(provider)
  const isVideo = useIsVideo(url)
  const isGIF = perceivedMediaType === globals.MediaType.GIF
  const isAnimated = isVideo || isGIF

  return (
    <div className={`pointer-events-none relative size-full overflow-hidden ${className}`}>
      <div
        className="absolute inset-0 scale-110 bg-cover bg-center object-cover blur-xl"
        style={{ backgroundImage: `url(${url.thumbnail})` }}
      />
      <div className="absolute inset-0 bg-black/50" />
      {isAnimated && <PlayPauseToggle />}
      <div className={`pointer-events-none absolute inset-0 ${Z_VIDEO_OVERLAY}`}>
        <div
          className={`pointer-events-none absolute inset-x-0 bottom-0 bg-gradient-to-t from-black via-black/80 to-transparent`}
          style={{ height: 275 }}
        />
        <div className={`absolute inset-x-0 flex flex-col ${isLoggedIn ? 'bottom-16' : 'bottom-6'}`}>
          <div className="pointer-events-none mb-4 flex flex-row">
            <div className="pointer-events-none ml-4 flex w-4/5 flex-col justify-end space-y-2">
              <div className="flex space-x-px">
                {isGIF ? (
                  <IconGIF className="peer cursor-pointer opacity-50 hover:opacity-100" />
                ) : isVideo ? (
                  <VolumeControl />
                ) : null}
              </div>
              <UrlShareDetails url={url} />
              <UrlTitle url={url} />
            </div>
            <div className="pointer-events-auto mr-4 flex w-1/5 justify-end">
              <UrlActions url={url} />
            </div>
          </div>
          <div className="pointer-events-auto mb-2 mt-auto flex flex-row px-4">
            <LikersAndTopics url={url} />
          </div>
        </div>
      </div>
      <UrlMedia url={url} isActive={isActive} />
      <div
        className={`pointer-events-auto absolute inset-x-0 ${Z_VIDEO_OVERLAY} ${isLoggedIn ? 'bottom-[3.4rem]' : 'bottom-4'}`}
      >
        {isActive && <ProgressBar playerRef={playerRef} />}
      </div>
    </div>
  )
}

export default Url
