import { useInView } from 'react-intersection-observer'

import { AuthLink } from '@desktop/login/AuthLink'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import IconApple from '@icons/IconApple'
import IconFacebook from '@icons/IconAuthFacebook'
import IconTwitter from '@icons/IconAuthTwitter'
import IconCaret, { CaretDirection } from '@icons/IconCaret'
import IconEmail from '@icons/IconEmail'
import IconSpinner from '@icons/IconSpinner.svg'
import GoogleSignInButton from '@mobile/GoogleSignInButton'
import { curatorCount } from '@pages/home/utils'
import { selectCurrentFeedItemKey } from '@redux/slices/feedSlice'
import { setIsLoginModalOpen, setLoginModalInnerUI } from '@redux/slices/modals/loginModalSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'

export const LOGIN_SLIDE_URL_ID = 'initial_login'

const LoginSlide = () => {
  const { ref, inView } = useInView({ threshold: 0, delay: 250 })
  const currentFeedItemKey = useAppSelector(selectCurrentFeedItemKey)

  const isFirstSlide = currentFeedItemKey === null || currentFeedItemKey === LOGIN_SLIDE_URL_ID
  return (
    <div className="relative h-dvh w-full overflow-hidden" ref={ref}>
      <HeroVideo />
      <div className="absolute inset-0 flex flex-col items-center">
        <div className="flex grow flex-col items-center justify-center px-4 text-center text-primary/90">
          <HeroText isInitialFeedItem={isFirstSlide} />
          <p className="mb-6 text-xs font-bold text-orange-400/80">JOIN {curatorCount()} CURATORS TODAY</p>
          <div className="flex flex-col items-center space-y-4">
            <GoogleSignInButton isVisible={isFirstSlide || inView} />
            <p className="font-semibold text-primary/70">Or, Continue with</p>
            <OtherAuthMethods />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

const HeroText = ({ isInitialFeedItem }: { isInitialFeedItem: boolean }) =>
  isInitialFeedItem ? (
    <>
      <div className="relative mb-10 mt-[10%] text-6xl font-bold leading-none tracking-tight text-white">
        <span className="absolute inset-x-0 top-0 h-30 bg-black blur-2xl" />
        <p className="relative">Expand</p>
        <p className="relative">your mind</p>
      </div>
      <p className="relative mb-8 px-8 text-lg font-semibold leading-tight text-white/60">
        <span>Discover the gems of the web, personalized to you.</span>
      </p>
    </>
  ) : (
    <div className="mb-10 mt-[70%] text-xl font-medium leading-tight">
      <div className="">
        <p>
          The coolest stuff on the internet, <br />
          curated by people like you.
        </p>
      </div>
    </div>
  )

const HeroVideo = () => (
  <div className="absolute inset-0 w-full">
    <div className="relative -mt-20 w-full">
      <video
        className="w-full object-cover"
        muted
        autoPlay
        loop
        playsInline
        poster="https://assets.mix.com/static/onboarding/intro_iphone.jpg"
      >
        <source src="https://assets.mix.com/static/onboarding/intro_iphone.mp4" type="video/mp4" />
      </video>
      <div className="absolute inset-x-0 bottom-0 h-full w-screen bg-gradient-to-t from-black to-black/30" />
    </div>
  </div>
)

const OtherAuthMethods = () => {
  const dispatch = useAppDispatch()
  const trackClick = useTrackClick()

  const authMethods = [
    { name: 'Facebook', Icon: IconFacebook },
    { name: 'X/Twitter', Icon: IconTwitter },
    { name: 'Apple', Icon: IconApple },
    { name: 'Email', Icon: IconEmail, isEmail: true },
  ]

  const iconSize = 20

  return (
    <div className="grid grid-cols-2 gap-2">
      {authMethods.map(({ name, Icon, isEmail }) =>
        isEmail ? (
          <button
            key={name}
            onClick={() => {
              trackClick('emailAuth', { source: 'login-slide-mobile' })
              dispatch(setLoginModalInnerUI('email'))
              dispatch(setIsLoginModalOpen(true))
            }}
            className="btn flex items-center space-x-2 bg-white/15 px-4 py-2 text-base font-medium text-white"
          >
            <Icon size={iconSize} />
            <span>{name}</span>
          </button>
        ) : (
          <AuthLink
            key={name}
            name={name}
            buttonClass="btn flex items-center space-x-2 bg-white/15 text-white font-medium text-base px-4 py-2"
            parentSource="login-slide-mobile"
          >
            {isSubmitting => (
              <>
                {isSubmitting ? <IconSpinner className="h-6" /> : <Icon size={iconSize} />}
                <span>{name}</span>
              </>
            )}
          </AuthLink>
        )
      )}
    </div>
  )
}

const Footer = () => (
  <div className="relative bottom-0 flex w-full flex-col items-center p-6">
    <div className="flex items-center text-center leading-tight text-white">
      <IconCaret direction={CaretDirection.Up} className="mr-2" size={14} />
      <p>Swipe up to discover</p>
    </div>
    <div className="absolute inset-x-0 bottom-0 h-12 w-screen bg-gradient-to-t from-black to-black/30" />
  </div>
)

export default LoginSlide
