import { useRouter } from 'next/router'
import { useCallback } from 'react'
import toast from 'react-hot-toast'

import { IconThumb } from '@common/IconThumb'
import { useModalActions } from '@components/Modal'
import { UrlShareModal } from '@components/share/UrlShareModal'
import { useBottomSheet } from '@context/BottomSheetContext'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useSidePanel } from '@context/SidePanelContext'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import { useRequest } from '@hooks/useRequest'
import IconEllipsis from '@icons/IconEllipsis.svg'
import IconShare from '@icons/IconShare.svg'
import IconSpeechBubble from '@icons/IconSpeechBubble.svg'
import { ContrastContainer } from '@mobile/ContrastContainer'
import { IntoUrl } from '@models/IntoUrl'
import { UrlComments } from '@pages/url/components/UrlComments'
import { UrlSidePanel } from '@pages/url/components/UrlSidePanel'
import { useToggleRating } from '@pages/url/hooks/useToggleRating'
import urlApi from '@redux/api/urlApi'
import { selectIntoConfig } from '@redux/slices/appSlice'
import { goToNextFeedItem } from '@redux/slices/feedSlice'
import { setLoginModalState } from '@redux/slices/modals/loginModalSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import IntoAPI from '@services/IntoAPI'
import { Z_VIDEO_OVERLAY } from './Url'

const COMMON_BUTTON_CLASSNAME = 'flex items-center justify-center rounded-full backdrop-blur-[2px]'
const NEXT_FEED_ITEM_DELAY_MS = 500

const UrlActions = ({ url: _url }: { url: IntoUrl }) => {
  const { isVisible: isShareModalVisible, toggleVisibility: toggleShareModalVisibility } = useModalActions()
  const { isLoggedIn } = useCurrentUserContext()
  const trackClick = useTrackClick()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { openSidePanel } = useSidePanel()
  const intoConfig = useAppSelector(selectIntoConfig)
  const { openBottomSheet } = useBottomSheet()

  const { rateUrlNone, rateUrlUp, rateUrlDown } = useToggleRating(_url.url_id)
  const url = urlApi.useGetUrlDetailsQuery({ urlId: _url.url_id }, { skip: !_url.url_id }).data?.url[0] || _url
  const { data: counts } = useRequest(IntoAPI.url.getCounts({ url_id: url.url_id }))

  const isUrlLiked = url?.meta?.isInto
  const isUrlDisliked = url?.meta?.isDisliked

  const handleLikeClick = useCallback(() => {
    if (!isLoggedIn) {
      return setLoginModalState({
        dispatch,
        copyVariant: 'login',
        isLoginModalOpen: true,
        returnPathOnClose: router.asPath,
        returnUrl: router.asPath,
      })
    }

    if (isUrlLiked) {
      trackClick('urlRemoveLike')
      rateUrlNone().catch(() => {})
    } else {
      trackClick('urlLike')
      rateUrlUp().catch(() => {})
    }
  }, [router.asPath, dispatch, isLoggedIn, isUrlLiked, trackClick, rateUrlNone, rateUrlUp])

  const handleDislikeClick = useCallback(() => {
    if (!isLoggedIn) {
      return setLoginModalState({
        dispatch,
        copyVariant: 'login',
        isLoginModalOpen: true,
        returnPathOnClose: router.asPath,
        returnUrl: router.asPath,
      })
    }
    if (isUrlDisliked) {
      trackClick('urlRemoveDislike')
      rateUrlNone().catch(() => {})
    } else {
      trackClick('urlDislike')
      rateUrlDown().catch(() => {})
      toast(`You'll see less like that`, {
        position: 'top-center',
        duration: 2000,
        className: 'font-semibold mt-14',
      })
      setTimeout(() => dispatch(goToNextFeedItem(undefined)), NEXT_FEED_ITEM_DELAY_MS)
    }
  }, [router.asPath, dispatch, isLoggedIn, isUrlDisliked, trackClick, rateUrlNone, rateUrlDown])

  const handleClickMoreInfo = () => {
    openSidePanel(<UrlSidePanel url={url} />)
  }

  const handleCommentsClick = useCallback(() => {
    openBottomSheet(<UrlComments url={url} showDropdown={false} />)
  }, [openBottomSheet, url])

  return (
    <>
      <div className={`flex w-min flex-col space-y-4 self-end ${Z_VIDEO_OVERLAY}`}>
        <ContrastContainer
          className={`${COMMON_BUTTON_CLASSNAME} flex w-12 flex-col items-center justify-center`}
          url={url}
        >
          {/* Share Button */}
          <button
            className="p-3"
            onClick={() => {
              trackClick('urlShare')
              toggleShareModalVisibility()
            }}
          >
            <IconShare className="size-5 h-auto text-primary" />
          </button>

          {intoConfig?.displayOptions?.showComments || !isLoggedIn ? (
            <>
              <button onClick={handleCommentsClick}>
                <IconSpeechBubble className="size-8 h-auto text-primary" />
              </button>
              {counts?.comments ? (
                <div className="pb-2 text-xs font-semibold">{counts.comments}</div>
              ) : (
                <span className="pb-2"></span>
              )}
            </>
          ) : (
            <span className="pb-1"></span>
          )}
        </ContrastContainer>

        {/* Thumbs Up/Down Buttons */}
        <ContrastContainer className={`${COMMON_BUTTON_CLASSNAME} w-12 flex-col rounded-full`} url={url}>
          {/* Like Button */}
          <div className="group relative">
            <button className="flex items-center justify-center p-3" onClick={handleLikeClick}>
              <IconThumb direction="up" isSelected={isUrlLiked ?? false} size={20} />
            </button>
          </div>

          {/* Dislike Button */}
          <div className="group relative">
            <button className="flex items-center justify-center p-3" onClick={handleDislikeClick}>
              <IconThumb direction="down" isSelected={isUrlDisliked ?? false} size={20} />
            </button>
          </div>
        </ContrastContainer>

        {/* More Info Button */}
        <ContrastContainer className={`${COMMON_BUTTON_CLASSNAME} size-12`} url={url}>
          <button onClick={handleClickMoreInfo}>
            <IconEllipsis className="size-4 text-primary" />
          </button>
        </ContrastContainer>
      </div>
      <UrlShareModal url={url} isVisible={isShareModalVisible} onClose={toggleShareModalVisibility} />
    </>
  )
}

export default UrlActions
